import React from 'react';
import { graphql,Link } from 'gatsby';
import Layout from '../../components/Layout/Layout';
import Seo from '../../components/Layout/Seo';
import Header from '../../components/Layout/Header';
import ImageFromNewt from "../../components/Atomic/ImageFromNewt"
import GetAllSelections from '../../components/Atomic/Post/GetAllSelections';
import { StaticImage } from 'gatsby-plugin-image';

export default function index({ data }) {
  function defaultImageOrFromNewt(cover){
    if(cover.coverImage != null){
      return(
        <ImageFromNewt src={cover.coverImage.src} className={"rounded-lg custom-shadow-md"} alt={cover.specialFeatureName}/>
      )
    }else{
      <StaticImage
        src={'../../images/favicons/ogp-image.jpg'}
        layout="constrained"
        alt="ohmycottie"
        width={100}
        height={100}
      />
    }
  }
  return (
    <div>
      <Layout>
        <Header />
        <div className='md:max-w-screen-xl max-w-screen-md grid md:grid-cols-7 place-items-start gap-12 px-6 py-16 m-auto '>
          <main className='lg:col-span-5 md:col-span-4 place-items-start grid lg:grid-cols-3 md:grid-cols-2 gap-8'>
          {data.allNewtSpecialFeature.edges.map((edge, index) => {
            return (
              <div className='mb-12'>
              <Link to={`/special-feature/${edge.node.specialFeatureName}`} key={index} className=''>
                <div className='aspect-[3/2] overflow-hidden rounded-lg'>
                  {defaultImageOrFromNewt(edge.node)}
                </div>
              </Link>
                <h2 className='leading-normal py-4'>{edge.node.specialFeatureName}</h2>
              </div>
              
            )
          })}
          </main>
          <aside className='lg:col-span-2 md:col-span-3'>
            <GetAllSelections/>
          </aside>
        </div>
      </Layout>
    </div>

  )
}

export const allSpecialFeature = graphql`
query specialFeatureQuery {
    allNewtSpecialFeature {
      edges {
        node {
          coverImage {
            src
          }
          description
          specialFeatureName
        }
      }
    }
  }
  
`

export const Head = () => (
  <>
  <Seo
    title={"Special Feature"}
    description={"ohmyocttieで紹介したレシピやライフスタイルハックの紹介記事をまとめた特集ページです。"}
  />
  </>
)