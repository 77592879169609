import React from "react"
import ImageFromNewt from "../ImageFromNewt"
import CardSecondary from "./CardSecondary"
import { useStaticQuery, graphql } from "gatsby"

export default function GetAllRecipes({ search,showTitle=false,showBtn=true }) {
  const recipes = useStaticQuery(graphql`
    query getAllRecipe {
      allNewtRecipe {
        edges {
          node {
            eyecatch {
              src
            }
            recipeTitle
            slug
          }
        }
      }
    }
  `)
  const data = search[0].node.mostViewed.map(d => {
    const p = recipes.allNewtRecipe.edges.filter(recipe => {
      return d.selection_recipe === null ? false : d.selection_recipe.recipeTitle === recipe.node.recipeTitle
    })
    return p
  })
  return (
    <>
      {data.map((recipe, i) => {
        if(recipe.length > 0){
        return (
          <>
          <CardSecondary
            link={`/recipe/${recipe[0].node.slug}`}
            aspectRatio={"1/1"}
            key={i}
            placeBtnOutside={true}
            showBtn={showBtn}
          >
            <ImageFromNewt
              src={recipe[0].node.eyecatch.src}
              alt={recipe[0].node.recipeTitle}
            />
          </CardSecondary>
          <h4 className={showTitle ? "block text-sm leadng-normal":"hidden"}>{recipe[0].node.recipeTitle}</h4>
          </>
        )}
      })}
    </>
  )
}
