import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import GetRecipeFromAll from "./GetRecipeFromAll"
import GetLifestyleImage from '../Top/GetLifestyleImage';
import MarkerTitle from '../Typography/MakerTitle'

const GetAllSelections = () => {

  const selections = useStaticQuery(graphql`
    query selectionsQuery {
        allNewtSelection {
          edges {
            node {
              mostViewed {
                selection_recipe {
                  recipeTitle
                  slug
                }
              }
              pickUp {
                selection_lifestyle {
                  title
                  slug
                }
              }
            }
          }
        }
      }
      
    `)
  const mostViewed = selections.allNewtSelection.edges

  return (
    <>
      <MarkerTitle text={"Most Viewed"} className={"text-center w-full"} />
      <div className='grid grid-cols-2 gap-4 pt-12 mb-12'>
        <GetRecipeFromAll search={mostViewed} showTitle={true} showBtn={false} />
      </div>
      <MarkerTitle text={"Life Style"} className={"text-center w-full mb-8"} />
      {selections.allNewtSelection.edges.map((pickups, i) => {
        return (
          <>
            <Link to={`/life-style/${pickups.node.pickUp.selection_lifestyle.slug}`}  key={i}>
              <GetLifestyleImage title={pickups.node.pickUp.selection_lifestyle.title} className={"rounded-lg custom-shadow-md"} />
              <h4 className='leading-normal py-4'>{pickups.node.pickUp.selection_lifestyle.title}</h4>
            </Link>
          </>
        )
      })}

    </>
  )
}

export default GetAllSelections;
